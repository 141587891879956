import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { convertToCamel } from 'state-domains/utils/typeCast';
import { buildDeletePostsUrl } from 'state-domains/network/urls';

import { sendDeleteRequestWithXSRFToken } from '../../../utils';

export const deletePosts = (id: string): Observable<any> => {
    const url = buildDeletePostsUrl(id);
    return sendDeleteRequestWithXSRFToken(url).pipe(
        switchMap(({ response }: AjaxResponse<any>) => {
            const converted = convertToCamel<any>(response);
            return observableOf(converted);
        }),
    );
};
